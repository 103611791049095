.cart-item {
  display: flex;
  border: 1px solid grey;
  align-items: center;
  width: 300px;
  height: 120px;
}

.cart-item-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart-item img {
  height: 50px;
  width: 50px;
}
