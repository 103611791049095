.item-container {
  display: flex;
  flex-direction: column;
  margin: 3%;
  padding: 20px;
  border: 2px solid grey;
  box-shadow: 3px 3px 5px grey;
  width: 300px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.price-style {
  margin-top: 0px;
  display: flex;
}

.item-details {
  display: flex;
  flex-direction: column;
}
