.profile-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: white;
  border: 2px solid black;
  border-radius: 15px;
  top: 60px;
  right: 460px;
  padding-left: 0px;
}

.profile-dropdown-logged-out {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: white;
  border: 2px solid black;
  border-radius: 15px;
  top: 60px;
  right: 20px;
  padding-left: 0px;
}

.navbar .profile-dropdown button {
  color: black;
  width: 150px;
  height: 50px;
  margin: none;
  padding: none;
  text-decoration: none;
}

.navbar .profile-dropdown-logged-out button {
  color: black;
  width: 150px;
  height: 50px;
  margin: none;
  padding: none;
  text-decoration: none;
}

.hidden {
  display: none;
}

img {
  width: 150px;
  height: 150px;
}

.big-navbar .navbar {
  display: flex;
  top: 0px;
  flex-direction: row;
  height: 70px;
  margin-top: 0px;
  padding-top: 0px;
  align-items: center;
  justify-content: space-between;
  background-color: navy;
  position: sticky;
}

.navbar .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  width: 150px;
  height: 100px;
  text-decoration: none;
  color: white;
  background-color: none;
  border: none;
}

body {
  margin: 0px;
  padding: 0px;
}

.big-navbar button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  width: 1fr;
  height: 100px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: large;
  cursor: pointer;
}

.user-links {
  display: flex;
}

.header-link {
  text-decoration: none;
  color: white;
}

.profile-button {
  background-color: white;
}

.big-navbar .search-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 4px solid black;
  border-radius: 30px;
  height: 24px;
  background-color: white;
  width: 800px;
}

.big-navbar .search-span button {
  color: black;
  height: 24px;
  width: 100px;
}

.big-navbar .search-span input {
  margin-left: 10px;
  border: 0px;
  width: 700px;
}

.search-span input:focus {
  outline:transparent
}

.big-navbar {
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  position: sticky;
  top:0px;
}

.search-div {
  display: flex;
  justify-content: center;
  padding: 15px 0px 15px 0px;

}
