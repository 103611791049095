.item-page {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 70% 30%;
  justify-content: center;
  margin-left: 100px;
  margin-right: 100px;
}

.item-page-info {
  display: flex;
  border: 3px solid lightgray;
  box-shadow: 5px 5px 3px lightgray;
  align-items: center;
}

.item-page-sidebar h4 {
  word-break: unset;
}

.item-page .item-page-info img {
  width: 350px;
  height: 350px;
  margin: 10px;
  border: 2px solid black;
}

.item-page-sidebar {
  display: flex;
  flex-direction: column;
  border: 3px solid lightgray;
  box-shadow: 5px 5px 3px lightgray;
  margin-left: 10%;
  justify-content: space-between;
  padding: 35px;
}

.item-page-info .info-span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}

.info-span * {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.info-span span * {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.item-page-sidebar button {
  color: black;
  background-color: orange;
  height: 30px;
  border-radius: 15px;
  margin: 5px;
  border-radius: 1px solid black;
  width: 100%;
}

.item-page-sidebar .button-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.item-page-sidebar select {
  width: 65%;
}

.all-item-page {
  display: flex;
  flex-direction: column;
  margin-bottom: 125px;
}

.review-container {
  display: grid;
  grid-template-columns: 70% 30%;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
}

.review {
  margin: 10px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
}

.review h4 {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.review .rating {
  margin-left: 5px;
}

.review .review-message {
  margin-left: 5px;
  margin-top: 25px;
}

.review-box {
  margin: 10px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review-box * {
  margin: 5px;
}

.review-box h2 {
  text-align: center;
}

.review-box button {
  color: black;
  background-color: orange;
  height: 30px;
  border-radius: 15px;
  margin: 5px;
  border-radius: 1px solid black;
  width: 45%;
  cursor: pointer;
}

.review-box .button-holder {
  display: flex;
  justify-content: space-around;
}

.review-box .rating-in-review-box * {
  margin: 0px;
}

.review-box textarea {
  resize: none;
  margin: 0px;
  width: 90%;
  box-sizing: border-box;
  vertical-align: middle;
  position: relative;
  right: 5px;
}

.review-box .rating-in-review-box {
  display: flex;
  justify-content: center;
}

.review-box .review-message-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.review-box form {
  width: 100%;
}

.review-container h2 {
  text-align: center;
}

.review-container h4 {
  text-align: center;
}

.review-container-new-div {
  border: 3px solid lightgray;
  box-shadow: 5px 5px 3px lightgray;
}
