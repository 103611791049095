.cart-holder {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 50px;
}

.purchase-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 300px;
  margin-top: 50px;
  color: black;
  background-color: orange;
  border-radius: 15px;
  border-radius: 1px solid black;
}

.cart-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 125px;
}
