.login-page {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.login-page form{
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid lightgrey;
  padding: 30px;
}

.login-page form label {
  display: inline-flex;
  font-size: medium;
  margin-bottom: 5px;
  font-weight: bold;
}

.login-page form input {
  width: calc(100% - 20px);
  font-size: large;
  padding: 10px;
}

.login-page form span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 10px;
}

.login-page button {
  color: black;
  background-color: orange;
  height: 30px;
  border-radius: 15px;
  margin: 5px;
  border-radius: 1px solid black;
  font-size: larger;
  width: 250px;
  cursor: pointer;
}

.login-page h1 {
  margin-bottom: 0px;
}

.login-page .login-errors {
  color: red;
}
