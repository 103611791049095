.footer {
  display: flex;
  background-color: navy;
  color: white;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer .link {
  cursor: pointer;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer h4 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  word-break: unset;
}
